import React from 'react';

const BirzhaMenuItem = ({ number, name, logo, isSelected, onSelect }) => {
    const handleClick = () => {
        onSelect(name);
    };
    return (
        <div className={`birzha-menu-item ${isSelected ? 'selected' : ''}` } onClick={handleClick}>
            <span className="item-number">{number}</span>
            <img src={logo} alt={`${name} logo`} className="item-logo" />
            <span className="item-name">{name}</span>
        </div>
    );
};

export default BirzhaMenuItem;

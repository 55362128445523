import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import './footer.css';
import rocket from './rocket.svg';
import wings from './wings.svg';
import friends from './friends.svg';
import coin from './coin.svg';

const Footer = forwardRef(({ activeComponent, setActiveComponent, vibrationEnabled, isSwipe, setIsSwipe, onIconClick }, ref) => {
    const [items, setItems] = useState([
        { id: 'tasks', img: rocket },
        { id: 'casher', img: wings },
        { id: 'friends', img: friends },
        { id: 'airdrop', img: coin },
    ]);
    const [isAnimating, setIsAnimating] = useState(false);
    const containerRef = useRef(null);

    useImperativeHandle(ref, () => ({
        updateActiveComponent(newComponent) {
            const activeIndex = items.findIndex(item => item.id === newComponent);
            const currentIndex = items.findIndex(item => item.id === activeComponent);
            if (activeIndex > currentIndex) {
                for (let i = 0; i < activeIndex - currentIndex; i++) {
                    handleRightClick(true);
                }
            } else if (activeIndex < currentIndex) {
                for (let i = 0; i < currentIndex - activeIndex; i++) {
                    handleLeftClick(true);
                }
            }
        },
        handleSwipe(deltaX) {
            if (containerRef.current) {
                const percentage = deltaX / window.innerWidth * 33.33; // Adjust sensitivity factor
                containerRef.current.style.transition = 'none';
                containerRef.current.style.transform = `translateX(calc(-33.33% + ${percentage}%))`;

                // Update icon sizes based on swipe position
                const newSizes = calculateIconSizes(deltaX);
                updateIconSizes(newSizes);
            }
        },
        handleSwipeEnd() {
            if (containerRef.current) {
                containerRef.current.style.transition = 'transform 0.2s ease';
                containerRef.current.style.transform = 'translateX(-33.33%)';
                resetIconSizes();
            }
        },
        resetIconSizes
    }));

    const calculateIconSizes = (deltaX) => {
        const activeIndex = items.findIndex(item => item.id === activeComponent);
        const nextIndex = deltaX > 0 ? (activeIndex - 1 + items.length) % items.length : (activeIndex + 1) % items.length;
        const percentage = Math.abs(deltaX) / window.innerWidth;

        const newSizes = items.map((item, index) => {
            if (index === activeIndex) {
                return 38 - 18 * percentage; // Active icon size decreases
            } else if (index === nextIndex) {
                return 20 + 18 * percentage; // Next icon size increases
            } else {
                return 20; // Other icons remain the same size
            }
        });

        return newSizes;
    };

    const updateIconSizes = (newSizes) => {
        items.forEach((item, index) => {
            const iconElement = document.getElementById(`footer-icon-${item.id}`);
            if (iconElement) {
                iconElement.style.width = `${newSizes[index]}px`;
                iconElement.style.height = `${newSizes[index]}px`; // Adjust height as well for consistent scaling
            }
        });
    };

    const resetIconSizes = () => {
        items.forEach(item => {
            const iconElement = document.getElementById(`footer-icon-${item.id}`);
            if (iconElement) {
                iconElement.style.width = item.id === activeComponent ? '38px' : '20px';
                iconElement.style.height = item.id === activeComponent ? '38px' : '20px'; // Reset height as well
            }
        });
    };

    const handleLeftClick = (isUpdate = false) => {
        if (!isAnimating) {
            handleVibration();
            setIsSwipe(false);
            setIsAnimating(true);
            const updatedItems = [
                items[items.length - 1],
                ...items.slice(0, items.length - 1)
            ];
            setItems(updatedItems);
            setActiveComponent(updatedItems[1].id, 'left');
            if (!isUpdate) {
                onIconClick(window.innerWidth); // Adjust deltaX value for background movement
            }
            animateItems('left');
        }
    };

    const handleRightClick = (isUpdate = false) => {
        if (!isAnimating) {
            handleVibration();
            setIsAnimating(true);
            setIsSwipe(false);
            const updatedItems = [
                ...items.slice(1),
                items[0]
            ];
            setItems(updatedItems);
            setActiveComponent(updatedItems[1].id, 'right');
            if (!isUpdate) {
                onIconClick(-window.innerWidth); // Adjust deltaX value for background movement
            }
            animateItems('right');
        }
    };

    const handleItemClick = (index) => {
        if (!isAnimating) {
            const activeIndex = items.findIndex(item => item.id === activeComponent);
            if (index === activeIndex) return;

            const clicks = index - activeIndex;
            if (clicks > 0) {
                for (let i = 0; i < clicks; i++) {
                    handleRightClick();
                }
            } else {
                for (let i = 0; i < Math.abs(clicks); i++) {
                    handleLeftClick();
                }
            }

            // Call onIconClick with the appropriate deltaX value
            const deltaX = clicks * (window.innerWidth / 3);
            onIconClick(deltaX);
        }
    };

    const handleVibration = () => {
        if (vibrationEnabled && window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');
        }
    };

    const animateItems = (direction) => {
        if (!isSwipe) {
            containerRef.current.style.transition = 'none';
            containerRef.current.style.transform = direction === 'left' ? 'translateX(-66.66%)' : 'translateX(0)';
            setTimeout(() => {
                containerRef.current.style.transition = 'transform 0.2s ease';
                containerRef.current.style.transform = 'translateX(-33.33%)';
                setTimeout(() => {
                    setIsAnimating(false);
                }, 500);
            }, 20)
        } else if (isSwipe) {
            containerRef.current.style.transition = 'none';
            containerRef.current.style.transform = direction === 'left' ? 'translateX(-66.66%)' : 'translateX(0)';
            containerRef.current.style.transform = 'translateX(-33.33%)';
            setTimeout(() => {
                setIsAnimating(false);
            }, 500);
        } else {
            setIsAnimating(false);
        }
    };

    useEffect(() => {
        const activeIndex = items.findIndex(item => item.id === activeComponent);
        if (activeIndex !== 1 && !isAnimating) {
            if (activeIndex > 1) {
                handleRightClick(true);
            } else {
                handleLeftClick(true);
            }
        }
    }, [activeComponent]);

    return (
        <div className="footer_block">
            <div className="footer_main_block">
                <div className="arrow_left" onClick={() => handleLeftClick(false)}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.5 11.5L3.5 6L9.5 0.5" stroke="#A6DFFF" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="footer_items_container">
                    <div className={`footer_items ${isAnimating ? 'animating' : ''}`} ref={containerRef} style={{ transform: 'translateX(-33.33%)' }}>
                        {[...items.slice(-1), ...items, ...items.slice(0, 1)].map((item, index) => (
                            <div
                                key={index}
                                className={`footer_item ${activeComponent === item.id ? 'active' : ''}`}
                                onClick={() => handleItemClick(index - 1)}
                                role="button"
                                tabIndex={0}
                            >
                                <img id={`footer-icon-${item.id}`} src={item.img} alt={item.id} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="arrow_right" onClick={() => handleRightClick(false)}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 11.5L9.5 6L3.5 0.5" stroke="#A6DFFF" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
        </div>
    );
});

export default Footer;

// VibroIcon.js
import React from 'react';

const VibroIcon = ({ isActive }) => (
    <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5 4.5L23.0047 6.9075C23.4607 7.63711 23.4607 8.56289 23.0047 9.2925L22.2453 10.5075C21.7893 11.2371 21.7893 12.1629 22.2453 12.8925L23.0047 14.1075C23.4607 14.8371 23.4607 15.7629 23.0047 16.4925L22.2453 17.7075C21.7893 18.4371 21.7893 19.3629 22.2453 20.0925L23.75 22.5" stroke={isActive ? "white" : "#015A99" } strokeWidth="1.125" strokeLinecap="round" />
        <path d="M3.5 4.5L1.99531 6.9075C1.53931 7.63711 1.53931 8.56289 1.99531 9.2925L2.75469 10.5075C3.21069 11.2371 3.21069 12.1629 2.75469 12.8925L1.99531 14.1075C1.53931 14.8371 1.53931 15.7629 1.99531 16.4925L2.75469 17.7075C3.21069 18.4371 3.21069 19.3629 2.75469 20.0925L1.25 22.5" stroke={isActive ? "white" : "#015A99" } strokeWidth="1.125" strokeLinecap="round" />
        <mask id="path-3-inside-1_0_1" fill="white">
            <path d="M5.75 0H19.25V27H5.75V0Z" />
        </mask>
        <path d="M5.75 0V-2.25H4.625V0H5.75ZM19.25 0H20.375V-2.25H19.25V0ZM19.25 27V29.25H20.375V27H19.25ZM5.75 27H4.625V29.25H5.75V27ZM5.75 2.25H19.25V-2.25H5.75V2.25ZM18.125 0V27H20.375V0H18.125ZM19.25 24.75H5.75V29.25H19.25V24.75ZM6.875 27V0H4.625V27H6.875Z" fill={isActive ? "white" : "#015A99"} mask="url(#path-3-inside-1_0_1)" />
    </svg>
);

export default VibroIcon;

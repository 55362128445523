import React, { useState, useEffect, useCallback} from 'react';
import './tasks.css';

import windx from './windx.svg';
import tasks_img from './tasks.svg';
import coin from './coin.svg';
import check from './check.svg'; // Import the checkmark icon
import { useLanguage } from './../../context/LanguageContext';
import { useSocket } from '../../context/SocketContext';
import { useCash } from '../../context/CashContext';

const Tasks = ({ userId, onEarnMg, isActive}) => {
    
    const { cash, setCash } = useCash();
    const [tasks, setTasks] = useState([]);
    const { language, translations } = useLanguage();
    const socket = useSocket();

    useEffect(() => {
        if (socket) {
            socket.emit('getTasks', userId, (data) => {
                if (data.error) {
                    console.error('Error fetching tasks:', data.error);
                } else {
                    const sortedTasks = data.sort((a, b) => a.completed - b.completed);
                    setTasks(sortedTasks);
                }
            });

            socket.emit('getUserData', userId, (data) => {
                if (data.error) {
                    console.error('Error fetching user data:', data.error);
                } else {
                    setCash(data.coins);
                }
            });

            socket.on('user_data_updated', (data) => {
                if (data.userId === userId) {
                    setCash(data.coins);
                }
            });

            return () => {
                socket.off('user_data_updated');
            };
        }
    }, [socket, userId]);

    useEffect(() => {
        if (isActive) {
            fetchTasks();
        }
    }, [isActive]);

    const fetchTasks = () => {
        socket.emit('getTasks', userId, (data) => {
            if (data.error) {
                console.error('Error fetching tasks:', data.error);
            } else {
                const sortedTasks = data.sort((a, b) => a.completed - b.completed);
                setTasks(sortedTasks);
            }
        });
    };

    const checkSubscription = useCallback(async (userId, chatId) => {
        const response = await fetch(`https://api.telegram.org/bot7316410586:AAH2wxpFGeXrnhBg9Csgy11Aa2fl5apO240/getChatMember?chat_id=${chatId}&user_id=${userId}`);
        const data = await response.json();
        return data.result && (data.result.status === 'member' || data.result.status === 'administrator' || data.result.status === 'creator');
    }, []);

    const handleTaskClick = async (task) => {
        if (task.completed) return;

        if (task.description.includes("Subscribe")) {
            const isSubscribed = await checkSubscription(userId, task.chat_id);
            if (isSubscribed) {
                await completeTask(task);
            } else {
                alert(translations[language].subscribe);
            }
        } else {
            await completeTask(task);
        }
    };

    const completeTask = async (task) => {
        socket.emit('completeTask', { userId, taskId: task.id, reward: task.reward }, (response) => {
            if (response.error) {
                alert("Failed to complete the task.");
            } else {
                const earnedCash = task.reward;
                const newCash = cash + earnedCash; 
                animateCashIncrease(cash, newCash);
                onEarnMg(task.reward);

                setTasks(prevTasks => {
                    const updatedTasks = prevTasks.map(t => {
                        if (t.id === task.id) {
                            return { ...t, completed: true };
                        }
                        return t;
                    });
                    return updatedTasks.sort((a, b) => a.completed - b.completed);
                });
            }
        });
    };

    const animateCashIncrease = (startValue, endValue) => {
        const duration = 1000;
        const startTime = performance.now();

        const animate = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const newValue = startValue + (endValue - startValue) * progress;
            setCash(Math.round(newValue * 10) / 10);
            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                setCash(Math.round(endValue * 10) / 10);
            }
        };

        requestAnimationFrame(animate);
    };

    return (
        <div className="tasks_block">
            <div className='tasks_money'>
                <img src={windx} alt="coin" />
                <p>{cash.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</p>
            </div>
            <h2>{translations[language].completeTasks}</h2>
            <div className="tasks_main_block">
            {tasks.length === 0 ? (
                <p className="no_tasks">{translations[language].noTasks}</p>
            ) : (
                <ul className="task_list">
                    {tasks.map(task => (
                        <li key={task.id} className={`task_item ${task.completed ? 'completed' : ''}`}>
                            <a href={task.channel_link}
                               target="_blank"
                               rel="noopener noreferrer"
                               onClick={(e) => e.stopPropagation()}>
                                <div className="task_title">
                                    <img src={tasks_img} alt="task" />
                                    <div className='block_task_item'>
                                        <div className="item_title">
                                            <h3>{task.description}</h3>
                                        </div>
                                        <div className="task_earn">
                                            <img src={coin} alt="" />
                                            <p>{task.reward}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>

                            {task.channel_link && (
                                task.completed ? (
                                    <div className="check_icon">
                                        <img src={check} alt="Completed" />
                                    </div>
                                ) : (
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="arrow_link"
                                        onClick={() => handleTaskClick(task)}
                                    >
                                        {translations[language].earn}
                                    </a>
                                )
                            )}
                        </li>
                    ))}
                </ul>
            )}
            </div>
        </div>
    );
};

export default Tasks;

import React, { useState, useEffect } from 'react';
import './airdrop.css';
import Coin from './coinIcon';
import clocks from './clocks.svg';
import { useLanguage } from '../../context/LanguageContext';

const calculateTimeLeft = () => {
    const year = new Date().getFullYear();
    const difference = +new Date(`01/01/${year + 1}`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

const Airdrop = ({ isDarkTheme }) => {
    const { language, translations } = useLanguage();
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    const rainbowColors = [
        '#EC5076', '#FF6534', '#FFC700', 'green', '#0071C1', '#F9F9F9', '#D017E0'
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatSeconds = () => {
        if (timeLeft.seconds === 0) {
            return "01:00";
        }
        return `00:${String(timeLeft.seconds).padStart(2, '0')}`;
    };

    const currentDayColor = rainbowColors[new Date().getDay()];

    return (
        <div className="airdrop_block">
            <div className="main_block_airdrop">
                <h1>{translations[language].airdropTitle}</h1>
                <div className="block_timer" style={{ color: currentDayColor }}>
                    <Coin color={currentDayColor}/>
                    <span>
                        {timeLeft.days}{translations[language].days} {timeLeft.hours}{translations[language].hours} {timeLeft.minutes}{translations[language].minutes}
                    </span>
                </div>
                <div className={`block_clocks ${isDarkTheme ? 'airDark' : ''}`}>
                    <div className="timer_main_block">
                        <div className="timer-block">
                            {formatSeconds()}
                        </div>
                    </div>
                    <img src={clocks} alt="clocks" />
                </div>
                <div className="block_button_airdrop">
                    <div className='button_airdrop'>
                        <p>{translations[language].connectWallet}</p>
                        <p>{translations[language].soon}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Airdrop;

import React, { useState, useEffect, useRef } from 'react';
import './header.css';
import settings from './settings.svg';

import defaultLogo from './birzha.svg';
import './birzha/birzha.css';
import sun from './sun.svg';
import close from './close.svg';
import moon from './moon.svg';
import VibroIcon from './vibroIcon';
import BirzhaMenu from './birzha/BirzhaMenu';
import binanceLogo from './birzha/binance.svg';
import okxLogo from './birzha/okx.svg';
import bybitLogo from './birzha/bybit.svg';
import htxLogo from './birzha/huobi.svg';
import bitgetLogo from './birzha/bitget.svg';
import bingxLogo from './birzha/bingx.svg';
import { useLanguage } from '../../context/LanguageContext';
import { useSocket } from './../../context/SocketContext';

const exchangeLogos = {
    Binance: binanceLogo,
    OKX: okxLogo,
    Bybit: bybitLogo,
    HTX: htxLogo,
    Bitget: bitgetLogo,
    Bingx: bingxLogo
};

const Header = ({ userId, vibrationEnabled, setVibrationEnabled, isDarkTheme, setIsDarkTheme }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showBirzha, setShowBirzha] = useState(false);
    const { language, setLanguage } = useLanguage();
    const [selectedExchange, setSelectedExchange] = useState('');
    const [selectedExchangeLogo, setSelectedExchangeLogo] = useState(defaultLogo);
    
    const menuRef = useRef(null);
    const socket = useSocket();
    const startXRef = useRef(null);
    const startYRef = useRef(null);
    const inactivityTimerRef = useRef(null);

    useEffect(() => {
        if (socket) {
            socket.emit('getUserPreferences', { userId }, (data) => {
                if (!data) {
                    console.error('No data received');
                    return;
                }
                if (data.error) {
                    console.error('Error fetching preferences:', data.error);
                } else {
                    setIsDarkTheme(data.theme === 'dark');
                    setVibrationEnabled(data.vibration_enabled);
                    setLanguage(data.language);
                    setSelectedExchange(data.selected_exchange || '');
                    setSelectedExchangeLogo(exchangeLogos[data.selected_exchange] || defaultLogo);
                    document.body.classList.toggle('dark-theme', data.theme === 'dark');
                }
            });
        }
    }, [socket, userId, setLanguage, setVibrationEnabled, setIsDarkTheme]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        const handleTouchStart = (event) => {
            const touch = event.touches[0];
            startXRef.current = touch.clientX;
            startYRef.current = touch.clientY;
        };

        const handleTouchMove = (event) => {
            if (!startXRef.current || !startYRef.current) {
                return;
            }

            const touch = event.touches[0];
            const diffX = touch.clientX - startXRef.current;
            const diffY = touch.clientY - startYRef.current;

            if (Math.abs(diffX) > Math.abs(diffY)) {
                closeMenu();
            }

            startXRef.current = null;
            startYRef.current = null;
        };

        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimerRef.current);
            inactivityTimerRef.current = setTimeout(() => {
                closeMenu();
            }, 5000); // 5 seconds of inactivity
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('mousemove', resetInactivityTimer);
        document.addEventListener('keydown', resetInactivityTimer);
        document.addEventListener('touchstart', resetInactivityTimer);

        resetInactivityTimer(); // Start the inactivity timer

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('mousemove', resetInactivityTimer);
            document.removeEventListener('keydown', resetInactivityTimer);
            document.removeEventListener('touchstart', resetInactivityTimer);
            clearTimeout(inactivityTimerRef.current);
        };
    }, []);

    const toggleMenu = () => {
        if (showMenu) {
            closeMenu();
        } else if (showBirzha) {
            closeMenu();
            setTimeout(() => {
                setShowMenu(true);
            }, 500); // Match the duration of the fadeOut animation
        } else {
            setShowMenu(true);
        }
    };

    const toggleBirzha = () => {
        if (showBirzha) {
            closeMenu();
        } else if (showMenu) {
            closeMenu();
            setTimeout(() => {
                setShowBirzha(true);
            }, 500); // Match the duration of the fadeOut animation
        } else {
            setShowBirzha(true);
        }
    };

    const handleVibrationToggle = () => {
        const newVibrationState = !vibrationEnabled;
        setVibrationEnabled(newVibrationState);
        savePreferences({ vibration_enabled: newVibrationState });
    };

    const handleThemeToggle = () => {
        const isDark = !isDarkTheme;
        setIsDarkTheme(isDark);
        document.body.classList.toggle('dark-theme', isDark);
        savePreferences({ theme: isDark ? 'dark' : 'light' });
    };

    const toggleLanguage = () => {
        const newLanguage = language === 'RU' ? 'EN' : 'RU';
        setLanguage(newLanguage);
        savePreferences({ language: newLanguage });
    };

    const handleExchangeSelect = (exchange) => {
        setSelectedExchange(exchange);
        setSelectedExchangeLogo(exchangeLogos[exchange]);
        savePreferences({ selected_exchange: exchange });
    };

    const closeMenu = () => {
        const menuElement = document.querySelector('.header_settings');
        if (menuElement) {
            menuElement.classList.add('fade-out');
            setTimeout(() => {
                setShowMenu(false);
                setShowBirzha(false);
            }, 500); // Match the duration of the fadeOut animation
        }
    };

    const savePreferences = (updates) => {
        const preferences = {
            userId,
            theme: isDarkTheme ? 'dark' : 'light',
            vibration_enabled: vibrationEnabled,
            language,
            selected_exchange: selectedExchange,
            ...updates
        };
        socket.emit('setUserPreferences', preferences, (response) => {
            if (response.error) {
                console.error('Error saving preferences:', response.error);
            }
        });
    };

    return (
        <div className="header">
            <img src={selectedExchangeLogo} alt="Selected Exchange" onClick={toggleBirzha} />
            <img src={settings} alt="Settings" onClick={toggleMenu} />
            {showBirzha && (
                <div className="header_settings" ref={menuRef}>
                    <img src={close} alt="" className="close-icon" onClick={closeMenu} />
                    <BirzhaMenu onSelectExchange={handleExchangeSelect} selectedExchange={selectedExchange} />
                </div>
            )}
            {showMenu && (
                <div className="header_settings" ref={menuRef}>
                    <img src={close} alt="" className="close-icon" onClick={closeMenu} />
                    <div className="dropdown-menu">
                        <div className="theme-switcher dropdown-item" onClick={handleThemeToggle}>
                            <img src={isDarkTheme ? moon : sun} alt="Theme Toggle Icon"/>
                        </div>
                        <div className="language-select dropdown-item" onClick={toggleLanguage}>
                            <p>{language}</p>
                        </div>
                        <div className={`circle_vibro dropdown-item ${vibrationEnabled ? 'active' : ''}`} onClick={handleVibrationToggle}>
                            <VibroIcon isActive={vibrationEnabled} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;

import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import Casher from './components/casher/casher';
import Tasks from './components/tasks/tasks';
import Friends from './components/friends/friends';
import Airdrop from './components/airdrop/airdrop';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useSocket } from './context/SocketContext';
import './App.css';
import LoadingScreen from './LoadingScreen'; // Import the LoadingScreen component

function App() {
    const [activeComponent, setActiveComponent] = useState('casher');
    const [mg, setMg] = useState(0);
    const [userId, setUserId] = useState('');
    const [vibrationEnabled, setVibrationEnabled] = useState(true);
    const [isSwipe, setIsSwipe] = useState(false);
    const [touchStartY, setTouchStartY] = useState(0);
    const [touchEndY, setTouchEndY] = useState(0);
    const [isDarkTheme, setIsDarkTheme] = useState(true);
    const [isLoading, setIsLoading] = useState(true); // State to track loading status
    const sliderRef = useRef(null);
    const footerRef = useRef(null);
    const backgroundPositionX = useRef(0); // Track background position
    
    const socket = useSocket();

    useEffect(() => {
        const initTelegram = () => {
            if (window.Telegram && window.Telegram.WebApp) {
                const user = window.Telegram.WebApp.initDataUnsafe.user;
                if (user) {
                    setUserId(user.id);
                }
            }
        };
        initTelegram();
    }, []);

    useEffect(() => {
        if (socket) {
            socket.emit('getUserPreferences', { userId }, (data) => {
                if (!data) {
                    console.error('No data received');
                    return;
                }
                if (data.error) {
                    console.error('Error fetching preferences:', data.error);
                } else {
                    setVibrationEnabled(data.vibration_enabled);
                    setIsDarkTheme(data.theme === 'dark');
                }
            });
        }
    }, [socket, userId]);

    useEffect(() => {
        if (isDarkTheme) {
            document.body.classList.add('resetting-background');
            document.body.style.backgroundPositionX = '0px';
            backgroundPositionX.current = 0;

            setTimeout(() => {
                document.body.classList.remove('resetting-background');
            }, 10); // Short delay to remove the transition class after reset
        }
    }, [isDarkTheme]);

    useEffect(() => {
        // Simulate loading time for demonstration purposes
        setTimeout(() => {
            setIsLoading(false);
        }, 2000); // Adjust the delay as needed
    }, []);

    const earnMg = (amount) => {
        setMg(prevMg => prevMg + amount);
    };

    const handleSwipe = (deltaX) => {
        document.body.classList.add('no-transition');
        const touchDifference = Math.abs(touchStartY - touchEndY);

        if (touchDifference > Math.abs(deltaX)) {
            return;
        }

        if (footerRef.current) {
            footerRef.current.handleSwipe(deltaX);
        }

        if (!isDarkTheme) {
            const newBackgroundPositionX = backgroundPositionX.current + deltaX;
            document.body.style.backgroundPositionX = `${newBackgroundPositionX}px`;
        }
    };

    const handleSwipeEnd = (eventData) => {
        document.body.classList.remove('no-transition');
        const touchDifference = Math.abs(touchStartY - touchEndY);

        if (touchDifference > Math.abs(eventData.deltaX)) {
            return;
        }

        if (footerRef.current) {
            setIsSwipe(true);
            footerRef.current.handleSwipeEnd(eventData);
        }

        if (!isDarkTheme) {
            backgroundPositionX.current += eventData.deltaX;
        }
    };

    const handleIconClick = (deltaX) => {
        document.body.classList.remove('no-transition');
        if (!isDarkTheme) {
            const newBackgroundPositionX = backgroundPositionX.current + deltaX;
            backgroundPositionX.current = newBackgroundPositionX;
            document.body.style.backgroundPositionX = `${newBackgroundPositionX}px`;
        }
    };

    const swipeHandlers = useSwipeable({
        onSwiping: (eventData) => handleSwipe(eventData.deltaX),
        onSwiped: (eventData) => handleSwipeEnd(eventData),
        onTouchStart: (event) => setTouchStartY(event.changedTouches[0].clientY),
        onTouchEnd: (event) => setTouchEndY(event.changedTouches[0].clientY),
        preventDefaultTouchmoveEvent: false,
        trackMouse: true,
    });

    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (current, next) => {
            const components = ['casher', 'friends', 'airdrop', 'tasks'];
            setActiveComponent(components[next]);
            if (footerRef.current) {
                footerRef.current.updateActiveComponent(components[next]);
            }
        },
        afterChange: (current) => {
            if (footerRef.current) {
                footerRef.current.resetIconSizes();
            }
        }
    };

    const handleFooterChange = (newComponent, direction) => {
        const components = ['casher', 'friends', 'airdrop', 'tasks'];
        const currentIndex = components.indexOf(activeComponent);
        const newIndex = components.indexOf(newComponent);
        if (newIndex !== currentIndex) {
            if (direction === 'right') {
                sliderRef.current.slickNext();
            } else {
                sliderRef.current.slickPrev();
            }
        }
    };

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className="App">
            <Header 
                userId={userId} 
                vibrationEnabled={vibrationEnabled} 
                setVibrationEnabled={setVibrationEnabled} 
                isDarkTheme={isDarkTheme}
                setIsDarkTheme={setIsDarkTheme}
            />
            <div className="content-container" {...swipeHandlers}>
                <Slider ref={sliderRef} {...sliderSettings}>
                    <div key="casher">
                        <Casher userId={userId} vibrationEnabled={vibrationEnabled}/>
                    </div>
                    <div key="friends">
                        <Friends userId={userId} onEarnBonus={earnMg} />
                    </div>
                    <div key="airdrop">
                        <Airdrop isDarkTheme={isDarkTheme}/>
                    </div>
                    <div key="tasks">
                        <Tasks userId={userId} onEarnMg={earnMg} isActive={activeComponent === 'tasks'} />
                    </div>
                </Slider>
            </div>
            <Footer 
                ref={footerRef}
                activeComponent={activeComponent} 
                setActiveComponent={handleFooterChange} 
                vibrationEnabled={vibrationEnabled} 
                isSwipe={isSwipe}
                setIsSwipe={setIsSwipe}
                onIconClick={handleIconClick}
            />
        </div>
    );
}

export default App;

import React from 'react';
import BirzhaMenuItem from './BirzhaMenuItem';
import binanceLogo from './binance.svg';
import okxLogo from './okx.svg';
import BybitLogo from './bybit.svg';
import HTXLogo from './huobi.svg';
import BitgetLogo from './bitget.svg';
import BingxLogo from './bingx.svg';

const BirzhaMenu = ({ onSelectExchange, selectedExchange }) => {
    return (
        <div className="birzha-menu">
            <BirzhaMenuItem 
                number="1" 
                name="Binance" 
                logo={binanceLogo} 
                isSelected={selectedExchange === 'Binance'}
                onSelect={onSelectExchange} 
            />
            <BirzhaMenuItem 
                number="2" 
                name="OKX" 
                logo={okxLogo} 
                isSelected={selectedExchange === 'OKX'}
                onSelect={onSelectExchange} 
            />
            <BirzhaMenuItem 
                number="3" 
                name="Bybit" 
                logo={BybitLogo} 
                isSelected={selectedExchange === 'Bybit'}
                onSelect={onSelectExchange} 
            />
            <BirzhaMenuItem 
                number="4" 
                name="HTX" 
                logo={HTXLogo} 
                isSelected={selectedExchange === 'HTX'}
                onSelect={onSelectExchange} 
            />
            <BirzhaMenuItem 
                number="5" 
                name="Bitget" 
                logo={BitgetLogo} 
                isSelected={selectedExchange === 'Bitget'}
                onSelect={onSelectExchange} 
            />
            <BirzhaMenuItem 
                number="6" 
                name="Bingx" 
                logo={BingxLogo} 
                isSelected={selectedExchange === 'Bingx'}
                onSelect={onSelectExchange} 
            />
        </div>
    );
};

export default BirzhaMenu;

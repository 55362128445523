import React, { useEffect, useState } from 'react';
import './avatar.css';

const Avatar = () => {
    const [appData, setAppData] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [initial, setInitial] = useState('null');

    useEffect(() => {
        const fetchAppData = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                const app = window.Telegram.WebApp;
                await app.ready();
                const appDataUnsafe = app.initDataUnsafe;
                setAppData(appDataUnsafe);
            }
        };
        fetchAppData();
    }, []);

    useEffect(() => {
        if (appData) {
            const nickname = appData.user?.username;
            if (nickname) {
                setAvatar(`https://unavatar.io/telegram/${nickname}`);
            }
        }
    }, [appData]);

    const name = appData?.user?.first_name;

    return (
        <div className="avatar_main">
            <div className="avatar">
                {avatar ? (
                    <img src={avatar} alt="User Avatar" />
                ) : (
                    <div className="default-avatar">
                        <span>{initial}</span>
                    </div>
                )}
                <p className="name">{name ? name : "Unknown User"}</p>
            </div>
        </div>
    );
};

export default Avatar;

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { createRoot } from "react-dom/client";
import { LanguageProvider } from './context/LanguageContext';
import { SocketProvider } from './context/SocketContext';
import { CashProvider } from './context/CashContext';

const container = document.getElementById('root')
const root = createRoot(container);
root.render(
        <Router>
          <LanguageProvider>
            <SocketProvider>
            <CashProvider>
              <App />
              </CashProvider>
            </SocketProvider>
          </LanguageProvider>
        </Router>
      );
// LanguageContext.js
import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('EN');
    const translations = {
        EN: {
            airdropTitle: "Big airdrop countdown",
            connectWallet: "Connect wallet",
            soon: "Soon",
            completeTasks: "Complete tasks and earn rewards",
            noTasks: "You don't have any tasks yet",
            earn: "Earn",
            days: "d",
            hours: "h",
            minutes: "m",
            invitedFriends: "Invited friends",
            getBonus: "Get W 100 for your friend and 10% of his mining",
            claim: "Claim",
            topFriends: "Top Friends",
            noReferrals: "You have no referrals yet.",
            inviteFriend: "Invite friend",
            clickToCopy: "Click to copy",
            farming: "Farming",
        play: "Play",
        subscribe: "Please subscribe to the Telegram channel to earn the reward."
        },
        RU: {
            airdropTitle: "Обратный отсчет большого airdrop",
            connectWallet: "Подключить кошелек",
            soon: "Cкоро",
            completeTasks: "Выполняй задачи и получай награды",
            noTasks: "У вас пока нет задач",
            earn: "Получить",
            days: "д",
            hours: "ч",
            minutes: "м",
            invitedFriends: "Приглашенные друзья",
            getBonus: "Получите W 100 за вашего друга и 10% от его майнинга",
            claim: "Забрать",
            topFriends: "Лучшие друзья",
            noReferrals: "У вас пока нет рефералов.",
            inviteFriend: "Пригласить друга",
            clickToCopy: "Нажмите, чтобы скопировать",
            farming: "Майнинг",
        play: "Начать",
        subscribe: "Подпишись на телеграм канал, чтобы получить награду"
            
        }
    };

    return (
        <LanguageContext.Provider value={{ language, setLanguage, translations }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);

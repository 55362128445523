import React, { createContext, useState, useContext } from 'react';

const CashContext = createContext();

export const CashProvider = ({ children }) => {
    const [cash, setCash] = useState(0);

    return (
        <CashContext.Provider value={{ cash, setCash }}>
            {children}
        </CashContext.Provider>
    );
};

export const useCash = () => {
    return useContext(CashContext);
};

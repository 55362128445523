import React, { useState, useEffect } from 'react';
import './casher.css';
import Scale from '../scale/scale';
import Avatar from '../avatar/avatar';
import wings from './wings.svg';
import stolb from './stolb.svg';
import { useSocket } from '../../context/SocketContext';
import Lottie from 'react-lottie';
import animationData from './claimAnimation.json';
import { useCash } from '../../context/CashContext';

const Casher = ({ userId, vibrationEnabled }) => {
    const { cash, setCash } = useCash();
    const [isFarming, setIsFarming] = useState(false);
    const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
    const socket = useSocket();

    const lottieOptions = {
        loop: false,
        autoplay: false,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        if (socket) {
            socket.emit('getUserData', userId, (data) => {
                if (data.error) {
                    console.error('Error fetching user data:', data.error);
                } else {
                    setCash(data.coins);
                }
            });
            socket.emit('getFarmingState', userId, (data) => {
                if (data.error) {
                    console.error('Error fetching user data:', data.error);
                } else {
                    setIsFarming(data.is_farming);
                }
            });

            socket.on('user_data_updated', (data) => {
                if (data.userId === userId) {
                    setCash(data.coins);
                }
            });

            return () => {
                socket.off('user_data_updated');
            };
        }
    }, [socket, userId, setCash]);

    const handleClaimAnimation = () => {
        setIsAnimationPlaying(true);
    };
    return (
        <div className="casher_block">
            <Avatar />
            <div className="block_cash">
                <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0H5.91746L9.11529 4.23254L8.19 5.25107L5.07919 1.35803H3.75947L8.19 6.84068L13.8034 0H15.8214L8.17777 9.50101L0.5 0Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.5 0.00012207H17.0825L13.8847 4.23266L14.81 5.25119L17.9208 1.35816H19.2405L14.81 6.8408L9.19662 0.00012207H7.17857L14.8222 9.50113L22.5 0.00012207Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.5 19.0001H17.0825L13.8847 14.7676L14.81 13.7491L17.9208 17.6421H19.2405L14.81 12.1594L9.19662 19.0001H7.17857L14.8222 9.49911L22.5 19.0001Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.5 19.0001H5.91746L9.11529 14.7676L8.19 13.7491L5.07919 17.6421H3.75947L8.19 12.1594L13.8034 19.0001H15.8214L8.17777 9.49911L0.5 19.0001Z" fill="white" />
                </svg>
                <p className="cash_text">{cash.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</p>
            </div>
            <div className='image_casher'>
            {isAnimationPlaying && (
                    <div className="lottie-container-casher">
                        <Lottie 
                            options={lottieOptions} 
                            height={400} 
                            width={400} 
                            isStopped={!isAnimationPlaying} 
                            eventListeners={[
                                {
                                    eventName: 'complete',
                                    callback: () => setIsAnimationPlaying(false),
                                }
                            ]}
                        />
                    </div>
                )}
                <div className={`wings ${isFarming ? 'spin' : ''}`}>
                    <img src={wings} alt="" />
                </div>
                <div className='stolb'>
                    <img src={stolb} alt="" />
                </div>
            </div>
            <Scale 
                userId={userId} 
                vibrationEnabled={vibrationEnabled} 
                setIsFarming={setIsFarming} 
                handleClaimAnimation={handleClaimAnimation} 
            />
        </div>
    );
};

export default Casher;

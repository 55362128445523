// VibroIcon.js
import React from 'react';

const CoinIcon = ({ color }) => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.28555 5.77783H7.98214L9.39546 7.93043L9.13237 8.25332L7.75689 6.1366H6.98038L9.19815 9.51754L11.5939 5.77783L12.0396 5.786L9.19815 10.218L6.28555 5.77783Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.27777 14.6665H7.97436L9.38768 12.5139L9.12459 12.191L7.74911 14.3077H6.97259L9.19037 10.9268L11.5861 14.6665L12.0318 14.6583L9.19037 10.2263L6.27777 14.6665Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7154 14.6666H13.0188L11.6055 12.514L11.8685 12.1911L13.244 14.3078H14.0205L11.8028 10.9269L9.40706 14.6666L8.96134 14.6585L11.8028 10.2264L14.7154 14.6666Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7154 5.77783H13.0188L11.6055 7.93043L11.8685 8.25332L13.244 6.1366H14.0205L11.8028 9.51754L9.38887 5.77783L8.94314 5.786L11.8028 10.218L14.7154 5.77783Z" fill={color} />
        <circle cx="10.5" cy="10" r="7.75" stroke={color} strokeWidth="0.5" />
        <circle cx="10.5" cy="10" r="9.5" stroke={color} />
    </svg>

);

export default CoinIcon;

import React, { useState, useEffect } from 'react';
import './friends.css';
import windx from './windx.svg';
import copy from './copy.svg';
import coin from './coin.svg';
import check from './check.svg';
import CoinIcon from './coin';
import { useLanguage } from '../../context/LanguageContext';
import { useSocket } from './../../context/SocketContext';
import Lottie from 'react-lottie';
import animationData from './../casher/claimAnimation.json';
import { useCash } from '../../context/CashContext';

const Friends = ({ userId, onEarnBonus }) => {
    const { language, translations } = useLanguage();
    const { cash, setCash } = useCash();
    const [referralLink, setReferralLink] = useState('');
    const [referrals, setReferrals] = useState([]);
    const [copied, setCopied] = useState(false);
    const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
    const socket = useSocket();

    const lottieOptions = {
        loop: false,
        autoplay: false,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const uniqueId = userId;
        const link = `https://t.me/WindX_bot?start=${uniqueId}`;
        setReferralLink(link);
    }, [userId]);

    useEffect(() => {
        if (socket) {
            socket.emit('getReferrals', userId, (data) => {
                if (data.error) {
                    console.error('Error fetching referrals:', data.error);
                } else {
                    const sortedReferrals = data.sort((a, b) => b.coins - a.coins);
                    setReferrals(sortedReferrals);
                }
            });

            socket.on('referrals_updated', (data) => {
                if (data.userId === userId) {
                    const sortedReferrals = data.referrals.sort((a, b) => b.coins - a.coins);
                    setReferrals(sortedReferrals);
                }
            });

            socket.emit('getUserData', userId, (data) => {
                if (data.error) {
                    console.error('Error fetching user data:', data.error);
                } else {
                    setCash(data.coins);
                }
            });

            return () => {
                socket.off('referrals_updated');
            };
        }
    }, [socket, userId, setCash]);

    const animateCashIncrease = (startValue, endValue) => {
        const duration = 1000;
        const startTime = performance.now();

        const animate = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const newValue = startValue + (endValue - startValue) * progress;
            setCash(Math.round(newValue * 10) / 10);
            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                setCash(Math.round(endValue * 10) / 10);
            }
        };

        requestAnimationFrame(animate);
    };

    const copyToClipboard = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(referralLink)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const handleInviteFriend = () => {
        const message = "I invite you to play this game!";
        const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(message)}`;
        window.open(telegramUrl, '_blank');
    };

    const handleClaim = () => {
        const totalCollectibleBonus = referrals.reduce((total, referral) => total + referral.collectible_bonus, 0);
        if (totalCollectibleBonus > 0) {
            socket.emit('claimReferralBonus', userId, (data) => {
                if (data.error) {
                    console.error('Error claiming bonus:', data.error);
                } else {
                    const newCash = cash + data.bonus; 
                    animateCashIncrease(cash, newCash);
                    onEarnBonus(data.bonus);
                    animateBonusReduction();
                    setIsAnimationPlaying(true); // Start the Lottie animation
                }
            });
        }
    };

    const animateBonusReduction = () => {
        setReferrals(prevReferrals =>
            prevReferrals.map(referral => {
                const initialBonus = referral.collectible_bonus;
                return {
                    ...referral,
                    collectible_bonus: initialBonus,
                    initialBonus
                };
            })
        );

        const duration = 1000;
        const startTime = performance.now();

        const updateBonuses = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            
            setReferrals(prevReferrals =>
                prevReferrals.map(referral => {
                    const newBonus = referral.initialBonus * (1 - progress);
                    return {
                        ...referral,
                        collectible_bonus: newBonus
                    };
                })
            );

            if (progress < 1) {
                requestAnimationFrame(updateBonuses);
            } else {
                setReferrals(prevReferrals =>
                    prevReferrals.map(referral => ({
                        ...referral,
                        collectible_bonus: 0
                    }))
                );
            }
        };

        requestAnimationFrame(updateBonuses);
    };

    return (
        <div className="friends_block">
            <div className='tasks_money'>
                <img src={windx} alt="coin" />
                <p>{cash.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</p>
            </div>
            <div className='container_invite'>
                <div className='invite_friends_block'>
                    <div className='dotcount'>
                        <p>{referrals.length}</p>
                    </div>
                    <p>{translations[language].invitedFriends}</p>
                </div>
            </div>
            <p className='text_friend'>{translations[language].getBonus}</p>
            <div className='block_claim'>
            {isAnimationPlaying && (
                <div className="lottie-container-friends">
                    <Lottie 
                        options={lottieOptions} 
                        height={200} 
                        width={300} 
                        isStopped={!isAnimationPlaying} 
                        eventListeners={[
                            {
                                eventName: 'complete',
                                callback: () => setIsAnimationPlaying(false),
                            }
                        ]}
                    />
                </div>
            )}
                <button onClick={handleClaim} disabled={referrals.reduce((total, referral) => total + referral.collectible_bonus, 0) === 0}>
                    {translations[language].claim}
                </button>
            </div>
            <div className="referrals_list">
                <h3>{translations[language].topFriends}</h3>
                {referrals.length > 0 ? (
                    <ul>
                        {referrals.map(referral => (
                            <div className='friend_item' key={referral.user_id}>
                                <div className='block_friend'>
                                    <div className='friend_img'>
                                        <img src={windx} alt="avatar" />
                                    </div>
                                    <div className='referral_info'>
                                        <li>{referral.display_name}</li>
                                        <div className='referral_info_money'>
                                            <CoinIcon color={'#5888BF'}/>
                                            <p>{referral.coins}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='block_money'>
                                    <div className='block_reward'>
                                        <img src={coin} alt="" />
                                        <p>{referral.collectible_bonus.toFixed(1)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </ul>
                ) : (
                    <p className='no_referral'>{translations[language].noReferrals}</p>
                )}
            </div>
            <div className="referral_link_container">
                <div className="referral_link">
                    <button onClick={handleInviteFriend}>{translations[language].inviteFriend}</button>
                    <a
                        href="/"
                        onClick={copyToClipboard}
                        title={translations[language].clickToCopy}
                    >
                        <img src={copied ? check : copy} alt="Copy referral link" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Friends;

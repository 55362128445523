import React, { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { useSocket } from '../../context/SocketContext';
import { useCash } from '../../context/CashContext';
import './scale.css';

const Scale = ({ userId, setIsFarming, vibrationEnabled, handleClaimAnimation }) => {
    const { cash, setCash } = useCash();
    const { language, translations } = useLanguage();
    const [isFarming, setLocalIsFarming] = useState(false);
    const [timeElapsed, setTimeElapsed] = useState(0); // Time elapsed in seconds
    const [earnedCash, setEarnedCash] = useState(0);
    const farmingDuration = 8 * 60 * 60; // 8 hours in seconds
    const vibrationIntervalRef = useRef(null);
    const socket = useSocket();
    const intervalRef = useRef(null);

    useEffect(() => {
        if (socket) {
            socket.emit('getFarmingState', userId, (data) => {
                if (data.error) {
                    console.error('Error fetching user data:', data.error);
                } else {
                    setLocalIsFarming(data.is_farming);
                    setIsFarming(data.is_farming);
                    setTimeElapsed(data.time_elapsed);
                    setEarnedCash(parseFloat(data.earned_cash)); // Ensure earnedCash is a number
                }
            });

            socket.on('farming_state_updated', (data) => {
                if (data.userId === userId) {
                    setLocalIsFarming(data.is_farming);
                    setIsFarming(data.is_farming);
                    setTimeElapsed(data.time_elapsed);
                    setEarnedCash(parseFloat(data.earned_cash)); // Ensure earnedCash is a number
                }
            });

            return () => {
                socket.off('farming_state_updated');
            };
        }
    }, [socket, userId, setIsFarming]);

    useEffect(() => {
        if (isFarming && timeElapsed < farmingDuration) {
            intervalRef.current = setInterval(() => {
                setTimeElapsed(prev => prev + 1);
                setEarnedCash(prev => prev + 0.1);
                socket.emit('updateFarmingState', { userId, is_farming: true, time_elapsed: timeElapsed + 1, earned_cash: earnedCash + 0.1 }, (response) => {
                    if (response.error) {
                        console.error('Error updating farming state:', response.error);
                    }
                });
            }, 1000);
        }

        if (timeElapsed >= farmingDuration && isFarming) {
            clearInterval(intervalRef.current);
            setLocalIsFarming(false);
            setIsFarming(false);
            socket.emit('updateFarmingState', { userId, is_farming: false, time_elapsed: timeElapsed, earned_cash: earnedCash }, (response) => {
                if (response.error) {
                    console.error('Error updating farming state:', response.error);
                }
            });
        }

        return () => clearInterval(intervalRef.current);
    }, [isFarming, timeElapsed, earnedCash, userId, farmingDuration, setIsFarming, socket]);

    const animateCashIncrease = (startValue, endValue, callback) => {
        const duration = 1000; // duration of the animation in ms
        const startTime = performance.now();

        const animate = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const newValue = startValue + (endValue - startValue) * progress;
            setCash(newValue);
            if (progress < 1) {
                requestAnimationFrame(animate);
            } else if (callback) {
                callback();
            }
        };

        requestAnimationFrame(animate);
    };

    const handleStartFarming = () => {
        setLocalIsFarming(true);
        setIsFarming(true); // Update farming state in Casher component immediately
        setTimeElapsed(0); // Reset elapsed time
        setEarnedCash(0); // Reset earned cash
        socket.emit('updateFarmingState', { userId, is_farming: true, time_elapsed: 0, earned_cash: 0 }, (response) => {
            if (response.error) {
                console.error('Error starting farming:', response.error);
            }
        });
    };

    const handleVibration = (start) => {
        if (vibrationEnabled) {
            if (start) {
                if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
                    window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');
                    vibrationIntervalRef.current = setInterval(() => {
                        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');
                    }, 100); // Vibrate every 100ms
                }
            } else {
                if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
                    clearInterval(vibrationIntervalRef.current);
                }
            }
        }
    };

    const handleClaim = () => {
        const newCash = cash + earnedCash;
        animateCashIncrease(cash, newCash, () => {
            // After the animation completes, update the backend
            socket.emit('updateUserData', { userId, coins: newCash }, (updateResponse) => {
                if (updateResponse.error) {
                    console.error('Error updating user cash:', updateResponse.error);
                }
            });
        });
        setEarnedCash(0);
        setLocalIsFarming(false);
        setIsFarming(false); // Update farming state in Casher component immediately
        setTimeElapsed(0); // Reset elapsed time

        const wingsElement = document.querySelector('.wings img');
        wingsElement.style.animation = 'none'; // Reset animation
        wingsElement.style.animation = 'spin-slowdown 2s cubic-bezier(0.33, 1, 0.68, 1)';

        handleVibration(true); // Start vibration

        setTimeout(() => {
            handleVibration(false); // Stop vibration
        }, 2000); // Duration of the animation

        handleClaimAnimation(); // Trigger Lottie animation in Casher component

        socket.emit('updateFarmingState', { userId, is_farming: false, time_elapsed: 0, earned_cash: 0 }, (response) => {
            if (response.error) {
                console.error('Error updating farming state:', response.error);
            }
        });
    };

    const formatTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        return `${h}${translations[language].hours} ${m}${translations[language].minutes}`;
    };

    const handleMainScaleClick = () => {
        if (!isFarming && timeElapsed >= farmingDuration) {
            handleClaim();
        } else if (!isFarming) {
            handleStartFarming();
        }
    };

    return (
        <div className="scale">
            <div className='mainScale' onClick={handleMainScaleClick}>
                <div className='fill' style={{ width: `${(timeElapsed / farmingDuration) * 100}%` }}>
                    {!isFarming && timeElapsed >= farmingDuration ? (
                        <div className='claim_button'>
                            {translations[language].claim} {earnedCash.toFixed(1)}
                        </div>
                    ) : !isFarming ? (
                        <div className='play_button'>
                            {translations[language].play}
                        </div>
                    ) : (
                        <div className='money_block'>
                            <p>{translations[language].farming}</p>
                            <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0H5.91746L9.11529 4.23254L8.19 5.25107L5.07919 1.35803H3.75947L8.19 6.84068L13.8034 0H15.8214L8.17777 9.50101L0.5 0Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M22.5 0.00012207H17.0825L13.8847 4.23266L14.81 5.25119L17.9208 1.35816H19.2405L14.81 6.8408L9.19662 0.00012207H7.17857L14.8222 9.50113L22.5 0.00012207Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M22.5 19.0001H17.0825L13.8847 14.7676L14.81 13.7491L17.9208 17.6421H19.2405L14.81 12.1594L9.19662 19.0001H7.17857L14.8222 9.49911L22.5 19.0001Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 19.0001H5.91746L9.11529 14.7676L8.19 13.7491L5.07919 17.6421H3.75947L8.19 12.1594L13.8034 19.0001H15.8214L8.17777 9.49911L0.5 19.0001Z" fill="white" />
                            </svg>
                            <div className="paper_count">{earnedCash.toFixed(1)}</div>
                            <div className="timer">
                                <p>{formatTime(farmingDuration - timeElapsed)}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Scale;

// VibroIcon.js
import React from 'react';

const CoinIcon = ({ color }) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.942688 0.43335H2.97859L4.67458 3.01647L4.35887 3.40394L2.70829 0.86387H1.77648L4.4378 4.921L7.31265 0.43335L7.84752 0.443146L4.4378 5.76157L0.942688 0.43335Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M0.93335 11.0999H2.96925L4.66524 8.51673L4.34953 8.12926L2.69896 10.6693H1.76714L4.42846 6.61221L7.30331 11.0999L7.83818 11.0901L4.42846 5.77164L0.93335 11.0999Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.0585 11.0999H9.02257L7.32658 8.51682L7.64229 8.12935L9.29287 10.6694H10.2247L7.56336 6.6123L4.68851 11.1L4.15364 11.0902L7.56336 5.77173L11.0585 11.0999Z" fill={color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.0585 0.43335H9.02257L7.32658 3.01647L7.64229 3.40394L9.29287 0.86387H10.2247L7.56336 4.921L4.66668 0.43335L4.13181 0.443146L7.56336 5.76157L11.0585 0.43335Z" fill={color}/>
</svg>

);

export default CoinIcon;
